import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { Container, Row, Col } from "react-bootstrap"
import { CurveBulgeBottom } from "../Curves/Curve"
import { mediaBreakpoint } from "../../utils/breakpoints"

const StyledBackgroundSection = styled(BackgroundImage)`
  width: 100%;
  min-height: 75vh;

  @media ${mediaBreakpoint.down.md} {
    min-height: 50vh;
  }
`

const ContentPositionCenter = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  padding: 0 50px 20px 50px;
  position: relative;

  > svg {
    max-width: 50px;
    margin-right: 15px;
  }

  span {
    display: inline-block;
    font-size: 30px;
    line-height: 2rem;
    color: ${({ theme, variant }) => theme[variant]};
  }

  @media ${mediaBreakpoint.down.md} {
    text-align: center;
    padding: 20px;

    > svg {
      margin: 5px;
    }

    span {
      /* font-size: 20px; */
    }

    h1 {
      font-size: 2rem;
    }
  }
`

const CurveTop = styled.svg`
  fill: ${({ theme }) => theme.primary};
  vertical-align: bottom;
`
const CurveBottom = styled.svg`
  transform: translateY(-50%);
  fill: ${({ theme, variant }) => theme[variant] || theme.secondary};
  vertical-align: top;
`

const ContentPositionBottom = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`

export const HeroImage = ({ fluid, variant, children, curveBottom }) => {
  return (
    <StyledBackgroundSection fluid={fluid}>
      <CurveBulgeBottom variant="white" />
      <ContentPositionCenter>
        <Container>
          <Row>
            <Col lg={8} className="mx-auto">
              <CurveTop viewBox="0 0 892 40.94">
                <path d="M0,0V40.94H892V0S423.74,49.53,0,0Z" />
              </CurveTop>

              <ContentWrapper variant={variant}>{children}</ContentWrapper>
              <CurveBottom variant={variant} viewBox="0 0 892 37.3">
                <path d="M0,25.9V0c0,0,406.4,23.1,892,0v25.9C892,25.9,453.1,51.7,0,25.9z" />
              </CurveBottom>
            </Col>
          </Row>
        </Container>
      </ContentPositionCenter>

      <ContentPositionBottom>{curveBottom}</ContentPositionBottom>
    </StyledBackgroundSection>
  )
}
