import React from "react"
import { PropertyCard } from "../Property/PropertyCard"
import { Row, Col, Button } from "react-bootstrap"
import { Link } from "gatsby"
import { CallMeBack } from "../Misc/CallMeBack"

export const ArticleSideBar = ({ relatedProperties, relatedArticles }) => {
  return (
    <div>
      {relatedProperties && (
        <div className="mb-5 ">
          <h6 className="mb-4" id="recommended-properties">
            Recommended Properties
          </h6>

          <Row>
            {relatedProperties.map(property => (
              <Col key={property.id} xs={12}>
                <PropertyCard property={property} />
              </Col>
            ))}
          </Row>

          <div className="text-center">
            <Button
              as={Link}
              to="/search-result"
              size="lg"
              className="text-center"
              variant="secondary"
            >
              Explore Investments
            </Button>
          </div>
        </div>
      )}

      {relatedArticles && (
        <div className="mb-5 ">
          <h6 id="related-articles">Related Articles</h6>

          {relatedArticles.map(article => (
            <div key={article.id}>
              <p>
                <Link to={article.permalink}>{article.title}</Link>
              </p>
            </div>
          ))}
        </div>
      )}

      <div className="mb-5 ">
        <h6>Are you curious?</h6>
        <p>
          Speak with an experienced consultant who will help identify suitable
          properties that will capture the exciting fundamental mentioned here.
        </p>

        <div className="text-center">
          <CallMeBack btnLg />
        </div>
      </div>
    </div>
  )
}
