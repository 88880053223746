import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { HeroImage } from "../components/Hero/HeroImage"
import { Icon } from "../components/IconFactory/Icons"
import { CurveBulgeBottom, CurveCarveTop } from "../components/Curves/Curve"
import { Newsletter } from "../components/Misc/Newsletter"
import { ArticleSideBar } from "../components/Article/ArticleSideBar"
import { MarkdownCommon } from "../components/Markdown/MarkdownCommon"
import { truncate } from "../utils/truncate"

const Article = ({ data: { contentfulArticle: article } }) => {
  // console.log(article)

  return (
    <Layout>
      <SEO
        title={article.seoTitle || article.title}
        description={
          article.snippet && truncate(article.snippet.snippet, 350, true)
        }
      />

      <HeroImage
        fluid={article.image && article.image.fluid}
        curveBottom={<CurveCarveTop variant="light" />}
      >
        <Icon name="logo" />
        <span>News &amp; Guidance</span>
        <h1 className="d-md-none">{article.titleMobile}</h1>
        <h1 className="d-none d-md-block">{article.title}</h1>
      </HeroImage>

      <div className="bg-light py-5">
        <Container>
          <Row>
            <Col md={8} className="mx-auto">
              <MarkdownCommon body={article.body} />
            </Col>
            <Col>
              <ArticleSideBar
                relatedProperties={article.relatedProperties}
                relatedArticles={article.relatedArticles}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <CurveBulgeBottom variant="light" />

      <Newsletter />
    </Layout>
  )
}

export const query = graphql`
  query ArticleDetailQuery($id: String) {
    contentfulArticle(id: { eq: $id }) {
      ...ArticleFragment
    }
  }
`

export default Article
