import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import styled from "styled-components"
import { Row, Button, Col } from "react-bootstrap"
import { Icon } from "../IconFactory/Icons"

const StyledPropertyCard = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  border-top: 15px solid ${props => props.theme.primary};
  margin-bottom: 60px;
  transition: all 0.2s ease-in-out;
  color: ${({ theme }) => theme.primary};

  &:hover {
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
    transform: translateY(-15px);
  }
`

const StyledPropertyCardHeader = styled.div`
  margin: 10px 0;

  svg {
    width: 50px;
    height: 50px;
  }

  p {
    margin: 0;
  }
`

const StyledPropertyImageWrapper = styled.div`
  position: relative;
  overflow: hidden;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 125%;
    height: 30px;
    background: #fff;
    z-index: 1;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &:before {
    top: -15px;
    border-bottom-right-radius: 100%;
    border-bottom-left-radius: 100%;
    border-bottom: 7px solid ${({ theme }) => theme.primary};
  }
  &:after {
    bottom: -5px;
    border-top-right-radius: 100%;
    border-top-left-radius: 100%;
    border-top: 7px solid ${({ theme }) => theme.primary};
  }
`

const StyledPropertyCardFooter = styled.div``

export const PropertyCard = ({ property }) => {
  // console.log(property)

  return (
    <StyledPropertyCard to={property.permalink}>
      <StyledPropertyCardHeader>
        <Col>
          <Row>
            <Col className="d-flex">
              <div>
                <Icon name={property.sector && property.sector.key} />
              </div>
              <div className="ml-2 ">
                <p>{property.name}</p>
                <p className={`text-${property.sector && property.sector.key}`}>
                  {property.sector && property.sector.subtitle}
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </StyledPropertyCardHeader>

      <StyledPropertyImageWrapper>
        {property.thumbnail && (
          <Img
            fixed={property.thumbnail.fixed}
            className="w-100"
            alt={property.thumbnail.title}
          />
        )}
      </StyledPropertyImageWrapper>

      <StyledPropertyCardFooter>
        <Col>
          <Row>
            <Col>
              <p className="text-center lead">{`£${property.startingPrice.toLocaleString(
                "en-GB"
              )}`}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <div>
                  Yield: <strong>{property.yield}%</strong>
                </div>
                <div>
                  Completion: <strong>{property.completionDateQuarter}</strong>
                </div>
              </div>
            </Col>
            <Col xs={5}>
              <Button block variant="secondary" size="sm">
                Get Details
              </Button>
            </Col>
          </Row>
        </Col>
      </StyledPropertyCardFooter>
    </StyledPropertyCard>
  )
}
